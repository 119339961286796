import React, { useState } from "react";
import "./Recommendations.css";

function Recommendations(props) {
  const recommenders = props.recommenders;
  const setRecommenders = props.setRecommenders;
  const setLoading = props.setLoading;
  const [userRecommendations, setUserRecommendations] = useState([]);
  const [showMovieInfo, setShowMovieInfo] = useState(false);
  const [movieDetails, setMovieDetails] = useState({});

  // const movieDetails = {
  //   id: 174,
  //   title_id: "tt0031381",
  //   movie_title: "Gone with the Wind",
  //   release_year: 1939,
  //   genre: "Drama, History, Romance, War",
  //   runtime: "238 min",
  //   director: "Victor Fleming, George Cukor, Sam Wood",
  //   actors: "Thomas Mitchell, Barbara O'Neil, Vivien Leigh, Evelyn Keyes",
  //   plot: "A manipulative woman and a roguish man conduct a turbulent romance during the American Civil War and Reconstruction periods.",
  //   poster:
  //     "https://m.media-amazon.com/images/M/MV5BYjUyZWZkM2UtMzYxYy00ZmQ3LWFmZTQtOGE2YjBkNjA3YWZlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
  // };

  const numMatches = recommenders.matchedRatings.length;

  const matchedUserList = recommenders.matchedRatings
    ? [...recommenders.matchedRatings.slice(0, 100)].map((e, i) => {
        let perfectMatch = recommenders.matchedRatings[i][3] === 0;
        let borderColor =
          recommenders.matchedRatings[i][3] === 0
            ? "bg-darkGray/60 border-paleGreen"
            : "bg-darkGray/60 border-darkGray";

        let movie_title1 = `${e[0].movie_title}`;
        let movie_title2 = `${e[1].movie_title}`;
        let movie_title3 = `${e[2].movie_title}`;

        let animate1 =
          movie_title1.length > 32 ? "animate-scrolling w-64 mr-2" : "";
        let animate2 =
          movie_title2.length > 32 ? "animate-scrolling w-64 mr-2" : "";
        let animate3 =
          movie_title3.length > 32 ? "animate-scrolling w-64 mr-2" : "";

        let colors = ["", "", ""];
        for (let i = 0; i < colors.length; i++) {
          let color;
          let numRating = parseInt(e[i].rating);
          if (numRating <= 4) {
            color = " text-paleRed font-bold";
          } else if (numRating <= 7) {
            color = " text-paleYellow font-bold";
          } else {
            color = " text-paleGreen font-bold";
          }
          colors[i] = color;
        }

        return (
          <div
            key={i}
            className={
              "group m-3 box-border flex h-32 w-80 flex-col rounded border-2 border-solid p-3 text-gray shadow-md shadow-black transition duration-100 ease-out hover:-translate-y-1 hover:cursor-pointer hover:border-white " +
              borderColor
            }
            onClick={() => showUserRecommendations(i)}
          >
            {perfectMatch ? (
              <div className="">
                <h1 className="font-bold text-gray">
                  USER {i + 1} - Perfect Match!
                </h1>
              </div>
            ) : (
              <div className="font-bold text-gray">
                <h1>USER {i + 1}</h1>
              </div>
            )}
            <div className="flex flex-row whitespace-nowrap overflow-x-hidden">
              <div className={"whitespace-nowrap overflow-x-hidden"}>
                <h1 className={"" + animate1}>{movie_title1}</h1>
              </div>
              <h1>
                : <span className={colors[0]}>{e[0].rating}</span>
              </h1>
            </div>
            <div className="flex flex-row whitespace-nowrap overflow-x-hidden">
              <div className={"whitespace-nowrap overflow-x-hidden"}>
                <h1 className={"" + animate2}>{movie_title2}</h1>
              </div>
              <h1>
                : <span className={colors[1]}>{e[1].rating}</span>
              </h1>
            </div>
            <div className="flex flex-row whitespace-nowrap overflow-x-hidden">
              <div className={"whitespace-nowrap overflow-x-hidden"}>
                <h1 className={"" + animate3}>{movie_title3}</h1>
              </div>
              <h1>
                : <span className={colors[2]}>{e[2].rating}</span>
              </h1>
            </div>
          </div>
        );
      })
    : [];

  const userRecommendationList = userRecommendations
    ? [...userRecommendations].map((e, i) => {
        let movie_title = `${e.movie_title} (${e.release_year})`;

        let color;
        let numRating = parseInt(e.rating);
        if (numRating <= 4) {
          color = " text-paleRed";
        } else if (numRating <= 7) {
          color = " text-paleYellow";
        } else {
          color = " text-paleGreen";
        }

        return (
          <div
            key={i}
            className="container relative m-2 box-border flex h-[100px] w-72 cursor-pointer flex-col rounded-lg border-2 border-solid bg-darkGray/60 transition duration-100 ease-out hover:-translate-y-1 hover:border-white md:h-[120px] md:w-64"
            onClick={() => showMovieInformation(e)}
          >
            <div className="z-10 flex grow px-3 pt-1 text-base text-gray">
              <h1>{movie_title}</h1>
            </div>
            <div className="z-10 flex justify-between px-3 pb-1 text-base text-gray">
              <h1>
                User Rating:{" "}
                <span className={"text-xl" + color}>{e.rating}</span>
              </h1>
              <h1 className=" self-end pb-[2px] text-xs"> + Details</h1>
            </div>
          </div>
        );
      })
    : [];

  async function showUserRecommendations(user) {
    setUserRecommendations(recommenders.recommendations[user]);
  }

  async function getMovieInfo(movie) {
    try {
      let response = await fetch("/movie-info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(movie),
      });
      let body = await response.json();
      return body;
    } catch (error) {
      console.log(error.message);
    }
  }

  async function showMovieInformation(movie) {
    setLoading(true);
    await getMovieInfo(movie)
      .then((res) => {
        if (res) {
          setShowMovieInfo(true);
          setMovieDetails(res);
          setLoading(false);
        } else {
          setShowMovieInfo(true);
          setMovieDetails({});
          setLoading(false);
        }
      })
      .catch((err) => {
        setShowMovieInfo(true);
        setMovieDetails({});
        setLoading(false);
      });
  }

  function hideMovieInfo() {
    setShowMovieInfo(false);
    setMovieDetails({});
  }

  return (
    <div className="container relative flex h-full flex-col overflow-x-hidden">
      <h1 className="flex-none self-center text-lg font-light text-yellow md:text-2xl md:font-bold">
        {userRecommendations.length > 0
          ? "User's Top Rated Movies"
          : `You Matched With ${numMatches} Reviewer(s)`}
      </h1>
      <h1 className="flex-none self-center text-xs  text-gray">
        {userRecommendations.length > 0
          ? ""
          : "Click on a matched user to see their recommendations"}
      </h1>
      <div className="container relative mt-3 flex flex-wrap justify-center self-center overflow-y-auto pb-[100px] overflow-x-hidden scrollbar-thin scrollbar-track-darkGray scrollbar-thumb-gray md:ml-3 md:scrollbar">
        {userRecommendations.length > 0
          ? userRecommendationList
          : matchedUserList}
      </div>
      {showMovieInfo ? (
        <div className="absolute bottom-0 left-0 z-20 flex h-full w-full justify-center bg-gradient-to-t from-black to-black/80 px-6 text-xl text-paleYellow transition duration-1000 ease-out md:pl-12 md:text-2xl">
          {movieDetails.movie_title && movieDetails.poster? (
            <div className="relative  flex h-[80%] w-auto flex-row justify-center border-darkGray md:h-3/4 md:border-solid">
              <img
                className="relative h-auto object-cover object-center brightness-[50%]"
                src={movieDetails.poster}
                alt=""
              />
              <div className="absolute -bottom-[10%] flex h-[90%] w-full flex-col bg-gradient-to-t from-black via-black/90 to-black/0 md:bottom-0 md:h-3/4">
                <div className="absolute bottom-0 flex h-fit w-full flex-col md:p-3 md:px-2">
                  <div className="text-lg text-gray md:px-3">
                    {movieDetails.movie_title} ({movieDetails.release_year})
                  </div>
                  <div className="flex pt-2 text-xs text-gray md:px-3 ">
                    {movieDetails.genre}
                  </div>
                  <div className="mb-4 flex text-xs text-gray md:grow md:px-3">
                    {movieDetails.runtime}
                  </div>
                  <div className="mb-4 flex text-xs text-gray md:grow md:px-3">
                    {movieDetails.plot}
                  </div>
                  <div className="mb-4 flex text-xs text-gray md:px-3">
                    Starring: {movieDetails.actors}
                  </div>
                </div>
                <div
                  className="fa-solid fa-left-long absolute -bottom-[15%] mb-10 cursor-pointer self-end px-3 text-xl md:-bottom-[15%] md:left-0 md:px-4"
                  onClick={hideMovieInfo}
                />
              </div>
            </div>
          ) : (
            <div className="relative  flex h-[80%] w-full flex-row justify-center border-darkGray md:h-3/4 md:border-solid">
              <div className="absolute -bottom-[10%] flex h-[90%] w-full flex-col bg-gradient-to-t from-black via-black/90 to-black/0 md:bottom-0 md:h-3/4">
                <div className="absolute bottom-0 flex h-fit w-[300px] flex-col md:p-3 md:px-2">
                  <div className="text-lg text-gray md:px-3">
                    No Data Available
                  </div>
                </div>
                <div
                  className="fa-solid fa-left-long absolute -bottom-[15%] mb-10 cursor-pointer self-end px-3 text-xl md:-bottom-[15%] md:left-0 md:px-4"
                  onClick={hideMovieInfo}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="absolute bottom-0 left-0 z-20 flex  h-[60px] w-screen bg-gradient-to-t from-black via-black/95 to-black/0 px-6 text-2xl text-paleYellow transition duration-1000 ease-out overflow-x-hidden md:h-[100px] md:text-2xl">
          <div
            className="fa-solid fa-left-long cursor-pointer self-end md:mb-5"
            onClick={
              userRecommendations.length > 0
                ? () => setUserRecommendations([])
                : () => setRecommenders([])
            }
          />
        </div>
      )}
    </div>
  );
}

export default Recommendations;
