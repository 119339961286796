import React, { useState } from "react";
import Recommendations from "../Recommendations/Recommendations";
import "./ForMe.css";

function ForMe(props) {
  const [movieIndexes, setMovieIndexes] = useState([]);
  const [numMovies, setNumMovies] = useState(0);
  // eslint-disable-next-line
  const [ratingChange, setRatingChange] = useState(0);
  const randomMovieList = props.randomMovieList;
  const recommenders = props.recommenders;
  const setRecommenders = props.setRecommenders;
  const setMoviesToRate = props.setMoviesToRate;
  const moviesToRate = props.moviesToRate;
  const loading = props.loading;
  const errorMsg = props.errorMsg;
  const movieSelected = props.movieSelected;

  // const moviesSelected = 3;
  // const errorMsg='select a number between 1 and 10'
  const backToSelect = () => {
    setMoviesToRate([]);
    setMovieIndexes([]);
    setRecommenders({});
    setNumMovies(0);
  };

  const handleSelect = (e, i) => {
    let newIndices = movieIndexes;
    let removeMovie = movieIndexes.findIndex((index) => index === i) >= 0;
    let index = movieIndexes.findIndex((index) => index === i);
    if (!removeMovie) {
      newIndices.push(i);
    } else {
      newIndices.splice(index, 1);
    }
    movieSelected(e, index);
    setMovieIndexes(newIndices);
    setNumMovies(newIndices.length);
  };

  const handleRating = (ev, i) => {
    let setRating;
    switch (i) {
      case 0:
        setRating = props.setRatingOne;
        break;
      case 1:
        setRating = props.setRatingTwo;
        break;
      case 2:
        setRating = props.setRatingThree;
        break;
      default:
        setRating = props.setRatingOne;
    }
    let rating = ev.target.value;
    setRating(ev);
    setRatingChange(rating);
  };

  const movieOptions = [...randomMovieList].map((e, i) => {
    let title_length =
      e.movie_title.length < 20
        ? "xs"
        : e.movie_title.length < 25
        ? "small"
        : e.movie_title.length < 35
        ? "mid"
        : e.movie_title.length < 42
        ? "lg"
        : "xl";
    let animate;
    switch (title_length) {
      case "small":
        animate = "animate-scrolling md:animate-none";
        break;
      case "mid":
        animate = " animate-scrollingSmall md:animate-scrolling";
        break;
      case "lg":
        animate = " animate-scrollingMid md:animate-scrolling";
        break;
      case "xl":
        animate = " animate-scrollingFast md:animate-scrollingSmall";
        break;
      default:
        animate = "";
    }
    // let animate = e.movie_title.length > 25? 'animate-scrollingSmall md:animate-scrolling':''
    let selected =
      movieIndexes.findIndex((index) => index === i) >= 0
        ? " bg-yellow border-yellow text-darkGray font-medium"
        : " bg-darkGray/70  border-darkGray text-gray";

    return (
      <div key={i} className={"relative mb-0.5  flex"}>
        <div
          id="movie-titles"
          className={
            "relative m-1 h-8 w-40 overflow-hidden whitespace-nowrap rounded border-2 border-solid p-2 text-center text-xs  hover:cursor-pointer md:my-2 md:h-12 md:w-80 md:rounded-xl md:p-3 md:text-base md:hover:border-yellow md:hover:bg-yellow md:hover:text-darkGray md:hover:font-medium" +
            selected
          }
          onClick={() => handleSelect(e, i)}
        >
          <h1 className={animate}>
            {e.movie_title} ({e.release_year})
          </h1>
        </div>
        {i >= 19 ? (
          <div
            className="fa-solid fa-shuffle absolute right-4 -bottom-8 flex cursor-pointer pt-1 text-xl text-paleYellow"
            onClick={() => props.refreshMovieList(movieIndexes)}
          ></div>
        ) : (
          <></>
        )}
      </div>
    );
  });

  const ratingMovies = [...moviesToRate].map((e, i) => {
    if (i > 2) {
      return null;
    }
    // let title_length =
    //   e.movie_title.length < 20
    //     ? "xs"
    //     : e.movie_title.length < 25
    //     ? "small"
    //     : e.movie_title.length < 35
    //     ? "mid"
    //     : e.movie_title.length < 42
    //     ? "lg"
    //     : "xl";
    // let animate;
    // switch (title_length) {
    //   case "small":
    //     animate = "animate-scrolling md:animate-none";
    //     break;
    //   case "mid":
    //     animate = " animate-scrollingSmall md:animate-scrolling";
    //     break;
    //   case "lg":
    //     animate = " animate-scrollingMid md:animate-scrolling";
    //     break;
    //   case "xl":
    //     animate = " animate-scrollingFast md:animate-scrollingSmall";
    //     break;
    //   default:
    //     animate = "";
    // }
    let translateValue = 10 * parseInt(e.rating);
    let translateString = String(translateValue);

    let color;
    let numRating = parseInt(e.rating);
    if (numRating <= 4) {
      color = " bg-paleRed";
    } else if (numRating <= 7) {
      color = " bg-paleYellow";
    } else {
      color = " bg-paleGreen";
    }

    return (
      <div
        key={i}
        className="my-3 flex flex-col justify-center md:my-8 md:flex-row"
      >
        <div className="mr-6 w-72 self-center text-[13px] text-gray overflow-x-hidden md:text-base">
          <h1 className="">
            {e.movie_title} {"(" + e.release_year + ")"}
          </h1>
        </div>
        <div
          id="rate-scale"
          className="relative mt-10 flex h-[35px] w-4/5 flex-row justify-center self-center rounded bg-darkGray/70 md:my-0 md:h-[50px] md:w-[380px]"
        >
          <div
            id="field"
            className="relative flex w-full items-center p-2 pt-4"
          >
            <div className="text-sm text-gray md:text-lg">0</div>
            <div className="relative mx-3 flex w-full">
              <input
                id="rating-slider"
                className="h-[3px] w-full appearance-none rounded border-none outline-none"
                type="range"
                value={e.rating}
                min="0"
                max="10"
                steps="1"
                onChange={(ev) => handleRating(ev, i)}
              ></input>
              <div
                id="slider-value"
                className="absolute z-10 w-full"
                style={{
                  transform: "translateX(" + translateString + "%)",
                }}
              >
                <div
                  className={
                    "absolute top-[-45px] h-[45px] w-[45px] translate-x-[-50%] text-center text-lg font-bold text-darkGray md:top-[-55px]"
                  }
                >
                  {e.rating}
                </div>
                <div
                  className={
                    "absolute top-[-50px] -z-10 h-[35px] w-[35px] translate-x-[-50%] rotate-45 rounded-tl-[50%] rounded-tr-[50%] rounded-bl-[50%] border-[3px] border-solid border-darkGray md:top-[-65px] md:h-[45px] md:w-[45px]" +
                    color
                  }
                ></div>
              </div>
            </div>
            <div className="text-sm text-gray md:text-lg">10</div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="relative flex h-full grow flex-col items-center overflow-y-hidden">
      {Object.keys(recommenders).length > 0 ? (
        <Recommendations
          recommenders={recommenders}
          ratedMovies={moviesToRate}
          setRecommenders={props.setRecommenders}
          loading={props.loading}
          setLoading={props.setLoading}
        />
      ) : (
        <div className="container relative flex h-full items-center">
          {numMovies === 3 ? (
            <div className="container relative mt-3 flex flex-col self-start px-3 overflow-x-hidden md:px-6">
              <div className="flex">
                <h1 className="text-lg font-light text-paleYellow">
                  Rate Your Selected Movies
                </h1>
              </div>
              {ratingMovies}
              <div className="container relative mt-8 px-3 flex justify-between">
                <div
                  className="fa-solid fa-left-long cursor-pointer pt-1 text-center hover:bg-darkGray rounded bg-darkGray/70 w-[100px] h-[25px] text-paleYellow"
                  onClick={backToSelect}
                ></div>
                <div
                  className="fa-solid fa-right-long cursor-pointer pt-1 text-center hover:bg-darkGray rounded bg-darkGray/70 w-[100px] h-[25px] text-paleYellow"
                  onClick={props.getRecommendations}
                >
                </div>
              </div>
              <h1 className="mt-4 h-6 text-xs text-red-500 md:text-sm">
                {errorMsg}
              </h1>
              {loading ? <div className=""></div> : <div></div>}
            </div>
          ) : (
            <div className="container relative mt-3 flex flex-col self-start">
              <div className="container relative flex space-x-2 px-2 py-3 pt-0 text-lg font-light text-paleYellow md:px-6">
                <h1>Select 3 Movies You Have Seen</h1>
              </div>
              <div className="w:auto relative flex flex-wrap justify-center self-center">
                {movieOptions}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ForMe;
