import React from "react";

function Info(props) {
  return (
    <div className="absolute right-[100%] top-[50%] z-30 flex w-[300px] flex-col border-2 p-4 pb-2 pt-2 rounded-lg bg-darkGray border-solid border-gray text-sm text-gray">
        <h1 className='text-lg text-paleYellow mb-3'>Welcome!</h1>
      <div className='flex mb-2'>
        <h1 className='mr-3'>1)</h1>
        <h1>Rate 3 movies you have seen</h1>
      </div>
      <div className='flex mb-2'>
        <h1 className='mr-3'>2)</h1>
        <h1>Get matched with others who rated those movies similarly</h1>
      </div>
      <div className='flex mb-2'>
        <h1 className='mr-3'>3)</h1>
        <h1>See what movies your match(es) recommend!</h1>
      </div>

      <h1 className='text-[12px] my-3'><span className='text-paleYellow'>Tip:</span> select movies you have unique opinions on (movies you like that everyone else seems to dislike or vice versa) to match with users who have the same unique taste as you.</h1>

      <h1 className='text-paleYellow text-right pt-3 text-[10px]'>created by nilanth yogadasan</h1>
    </div>
  );
}

export default Info;
