import "./App.css";
import React, { useEffect, useState, useCallback } from "react";
import ForMe from "./ForMe/ForMe";
// import { randomMovieList, moviesToRate, recommenders } from "./sampleData";
import Info from "./Info/Info";

function App() {
  const [findMoviesForMe, setFindMoviesForMe] = useState(true);
  //   eslint-disable-next-line
  const [findMoviesForGroup, setFindMoviesForGroup] = useState(false);
  const [randomMovieList, setRandomMovieList] = useState([
    ...Array(20).fill({ movie_title: "", release_year: "" }),
  ]);
  const [moviesSelected, setMoviesSelected] = useState([]);
  const [moviesToRate, setMoviesToRate] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(true);
  const [recommenders, setRecommenders] = useState({});

  function handleClickForMe() {
    setFindMoviesForMe(true);
  }

  function handleClickForGroup() {
    setFindMoviesForGroup(true);
  }

  function toggleInfo() {
    let infoStatus = info;
    setInfo(!infoStatus);
  }

  const getRandomMovieList = useCallback(async () => {
    try {
      let response = await fetch("/select-movies");
      let body = await response.json();
      return body;
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  async function refreshMovieList(movieIndexes) {
    let selectedMovies = moviesToRate;
    let newList = [...Array(20).fill("")];
    for (let i = 0; i < movieIndexes.length; i++) {
      newList[movieIndexes[i]] = selectedMovies[i];
    }
    getRandomMovieList()
      .then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (newList[i] === "") {
            newList[i] = res[i];
          }
        }
        setRandomMovieList(newList);
      })
      .catch((err) => setRandomMovieList(String(err)));
  }

  function movieSelected(e, indexToRemove) {
    let newMoviesToRate = moviesToRate;
    if (indexToRemove >= 0) {
      newMoviesToRate.splice(indexToRemove, 1);
      setMoviesToRate(newMoviesToRate);
    } else {
      newMoviesToRate.push(e);
      setMoviesToRate(newMoviesToRate);
    }
  }

  function setRatingOne(e) {
    let movie = moviesToRate[0];
    movie.rating = Math.round(Number(e.target.value));
    let movies_to_rate = moviesToRate;
    movies_to_rate[0] = movie;
    setMoviesToRate(movies_to_rate);
  }
  function setRatingTwo(e) {
    let movie = moviesToRate[1];
    movie.rating = Math.round(Number(e.target.value));
    let movies_to_rate = moviesToRate;
    movies_to_rate[1] = movie;
    setMoviesToRate(movies_to_rate);
  }
  function setRatingThree(e) {
    let movie = moviesToRate[2];
    movie.rating = Math.round(Number(e.target.value));
    let movies_to_rate = moviesToRate;
    movies_to_rate[2] = movie;
    setMoviesToRate(movies_to_rate);
  }

  async function submitRatings() {
    try {
      let response = await fetch("/recommend-movies", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(moviesToRate),
      });
      let body = await response.json();
      return body;
    } catch (error) {
      console.log(error.message);
    }
  }

  async function getRecommendations() {
    setLoading(true);
    if (
      !moviesToRate[0].rating ||
      !moviesToRate[1].rating ||
      !moviesToRate[2].rating ||
      moviesToRate[0].rating < 1 ||
      moviesToRate[0].rating > 10 ||
      moviesToRate[1].rating < 1 ||
      moviesToRate[1].rating > 10 ||
      moviesToRate[2].rating < 1 ||
      moviesToRate[2].rating > 10
    ) {
      console.log("hello");
      setErrorMsg("Enter a rating between 1 and 10 for each movie");
    } else {
      await submitRatings()
        .then((res) => {
          setRecommenders(res);
          setLoading(false);
        })
        .catch((err) => console.log(err.message));
    }
  }

  useEffect(() => {
    getRandomMovieList()
      .then((res) => setRandomMovieList(res))
      .catch((err) => setRandomMovieList(String(err)));
  }, [getRandomMovieList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <div className="flex h-full min-w-[375px] flex-col overflow-hidden bg-black">
      {loading ? (
        <div className="container absolute top-1/3 left-1/2 z-30 flex w-full translate-x-[-50%]  translate-y-[-50%] justify-center bg-black/90 p-4 text-center text-3xl text-paleYellow">
          <div className="fa-solid fa-spinner animate-spin-slow" />
          <h1 className="ml-3 self-center text-sm text-paleYellow">Loading</h1>
        </div>
      ) : (
        <></>
      )}
      <nav className="container relative mx-auto p-2 md:p-6">
        <div className="flex items-center justify-between">
          <div
            className="pt-2 text-3xl font-bold text-gray hover:cursor-pointer"
            onClick={() => window.location.reload()}
          >
            Movie<span className="text-yellow">4</span>Me
            <span className="text-xs font-light text-paleYellow"> by NY</span>
          </div>
          <div
            className="relative cursor-pointer self-center"
            onClick={toggleInfo}
          >
            <div className="fa-regular fa-circle-question pr-4 pt-2 text-2xl text-gray hover:text-paleYellow active:text-paleYellow"></div>
            {info ? <Info /> : <></>}
          </div>
        </div>
      </nav>
      {findMoviesForMe ? (
        <ForMe
          randomMovieList={randomMovieList}
          recommenders={recommenders}
          setRecommenders={setRecommenders}
          moviesToRate={moviesToRate}
          movieSelected={movieSelected}
          moviesSelected={moviesSelected}
          setRatingOne={setRatingOne}
          setRatingTwo={setRatingTwo}
          setRatingThree={setRatingThree}
          getRecommendations={getRecommendations}
          loading={loading}
          setLoading={setLoading}
          errorMsg={errorMsg}
          refreshMovieList={refreshMovieList}
          setMoviesSelected={setMoviesSelected}
          setMoviesToRate={setMoviesToRate}
        />
      ) : (
        <div className="flex h-full grow flex-col">
          <div className="flex h-full flex-col justify-center">
            <div
              id="main"
              className="container mx-auto flex flex-col px-6 md:mb-10 md:flex-row xl:w-3/5"
            >
              <div
                id="for-me-button"
                className="border-offWhite bg-darkGrayishBlue group mx-auto flex h-56 w-56 flex-col rounded border-2 border-solid hover:cursor-pointer md:h-72 md:w-72"
                onClick={handleClickForMe}
              >
                <div className="flex grow group-hover:bg-veryPaleRed"></div>
                <button className="text-offWhite group-hover:text-darkBlue mx-auto w-full flex-none p-6 px-6  font-light group-hover:bg-veryPaleRed md:text-xl">
                  Find Movies for Me
                </button>
              </div>
              <div
                id="for-me-button"
                className="border-offWhite bg-darkGrayishBlue group mx-auto mt-6 flex h-56 w-56 flex-col rounded border-2 border-solid hover:cursor-pointer md:mt-0 md:h-72 md:w-72"
                onClick={handleClickForGroup}
              >
                <div className="flex grow group-hover:bg-veryPaleRed"></div>
                <button className="text-offWhite group-hover:text-darkBlue mx-auto w-full p-6 px-6  font-light group-hover:bg-veryPaleRed md:text-xl">
                  Find Movies for Group
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="text-offWhite container relative mx-auto p-2 text-right md:p-3">
        {/* <h1 className='text-xs'>&#169;Nilanth Yogadasan, 2020</h1> */}
        <h1 className="text-xs">created by nilanth yogadasan</h1>
      </div>
    </div>
  );
}

export default App;
